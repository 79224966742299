const facultyTable = (() => {
  require('datatables.net-dt')();
  require('datatables.net-buttons')();

  const renameLabel = type => {
    if (type.toUpperCase() === 'NAME & TITLE') {
      return 'type';
    }

    if (type.toUpperCase() === 'OFFICE LOCATION') {
      return 'campusLocation';
    }

    return type.toLowerCase();
  };

  const directoryInit = () => {
    const $tableHolder = $('#faculty-table');
    const ajaxPath = $('#faculty-table').attr('api-url');

    function renderTable(sortedData) {
      const table = $tableHolder.DataTable({
        data: sortedData,
        processing: true,
        pagingType: 'simple_numbers',
        sPaginationType: 'ellipses',
        deferLoading: 57,
        ordering: false,
        info: false,
        drawCallback(settings) {
          const pagination = $(this)
            .closest('.dataTables_wrapper')
            .find('.dataTables_paginate');
          pagination.toggle(this.api().page.info().pages > 1);
        },
        language: {
          search: '<span class="search-title">Search Within Table</span>',
          searchPlaceholder: 'Type to filter',
          info: false,
        },
        columns: [
          {
            data: 'type',
          },
          {
            data: 'department',
          },
          {
            data: 'email',
          },
          {
            data: 'phone',
            visible: false
          },
          {
            data: 'campusLocation',
          },
        ],
        columnDefs: [
          {
            targets: 0,
            data: 'type',
            render(data, type, row) {
              if (row.url) {
                return `<div class="row-section">
                  <span class="row-label">Name & Title</span>
                  <div class="row-content">
                  <a class="link-name" href="${row.url}">${row.displayName}</a>
                  <span class="title">${row.title}</span>
                  <span class="type">${row.type}</span>
                  </div>
                  </div>`;
              }
              return `<div class="row-section">
                  <span class="row-label">Name & Title</span>
                  <div class="row-content">
                  <span class="link-name" >${row.displayName}</span>
                  <span class="title">${row.title}</span>
                  <span class="type">${row.type}</span>
                  </div>
                  </div>`;
            },
          },
          {
            targets: 1,
            data: 'department',
            render(data) {
              return `<div class="row-section"><span class="row-label">Department</span><span class="department">${data}</span></div>`;
            },
          },
          {
            targets: 2,
            data: 'email',
            render(data, type, row) {
              if (row.email.length !== 0) {
                if (row.phone === "") {
                  return `<div class="row-section"><span class="row-label">Contact</span><a class="email" href="mailto:${row.email}">${data}</a></div>`;
                }
                else {
                  return `<div class="row-section"><span class="row-label">Contact</span><a class="email" href="mailto:${row.email}">${data}</a><a class="tel" href="tel:${row.phone}">${row.phone}</a></div>`;
                }
              }
              else {
                return `<div class="row-section"></div>`;
              }
            },
          },
          {
            targets: 3,
            data: 'phone',
            render(data, type, row) {
              if (row.phone === "") {
                return `<div class="row-section"></div>`;
              }
              else {
                return `<div class="row-section"><span class="row-label">Phone</span><a class="tel" href="tel:${row.phone}">${data}</a></div>`;
              }
            },
          },
          {
            targets: 4,
            data: 'campusLocation',
            render(data, type, row) {
              return `<div class="row-section"><span class="row-label">Location</span><span class="campusLocation">${data}</span></div>`;
            },
          },
          {
            targets: '_all',
            orderable: false,
          },
        ],
        dom:
          '<"heading-table"B<"heading-table-filter"<"mobile-filter-drop"<"toolbar">rf>>><"wrapper-table"<"table-holder"t>><"footer-table"p<"table-info"li>>',
        buttons: [
          {
            text: 'Directory Filters<span class="toggle__icon"></span>',
            className: 'filter-btn-drop',
            action: () => {
              const $filterHolder = $('.heading-table .heading-table-filter');
              const $filterToggle = $('.heading-table .filter-btn-drop');

              $filterToggle.toggleClass('expanded');
              $filterHolder
                .stop()
                .slideToggle(300)
                .attr(
                  'aria-hidden',
                  $filterHolder.attr('aria-hidden') === 'false'
                    ? 'true'
                    : 'false',
                );
            },
          },
        ],
        keys: true,
        responsive: true,
        initComplete() {
          this.api()
            .columns()
            .every(function(i) {
              const column = this;
              const toolbar = $('.toolbar');
              const holder = document.createElement('div');
              const label = document.createElement('label');
              const searchHolder = $('#faculty-table_filter');
              const select = $(
                '<select class="form__control filter"><option value="">All</option></select>',
              );

              $(label).text(renameLabel(this.header().innerText));

              $(label)
                .addClass('form__label')
                .attr('for', `input-${i}`)
                .appendTo(holder);
              select.attr('id', `input-${i}`).appendTo(holder);
              $(holder)
                .addClass('filter-box')
                .appendTo(toolbar);
              searchHolder.appendTo(toolbar);

              select.on('change', function() {
                const val = $.fn.dataTable.util.escapeRegex($(this).val());

                if (column[0][0] === 1) {
                  column
                    .search(val ? `${val}$` : val, true, true, false)
                    .draw();
                } else {
                  column.search(val ? `${val}` : val, true, true, false).draw();
                }
              });

              column
                .data()
                .unique()
                .sort()
                .each(function(d, j) {
                  select.append(`<option value="${d}">${d}</option>`);
                });
            });
        },
      });

      table.on( 'search', () => {
        const reset = $('.clear-table');
        const filteredData = table.rows( {search:'applied'} ).count();
        const data = table.rows().count();

        if (data === filteredData) {
          reset.addClass('js-disabled');
          reset.attr('tabindex', '-1');
        }
        else {
          reset.removeClass('js-disabled');
          reset.removeAttr('tabindex');
        }
      });

      const renderBtnClear = container => {
        const btnClear = document.createElement('button');
        const iconClear = document.createElement('i');
        const filterHolder = $(container);
        $(btnClear)
          .addClass('clear-table js-disabled')
          .attr('tabindex', '-1')
          .html('<span>Reset filter</span>');
        $(btnClear).insertAfter(filterHolder);
      };

      const resetFilter = () => {
        $('.clear-table').on('click', function(e) {
          e.preventDefault();
          $('.filter').prop('selectedIndex', 0);
          table
            .search('')
            .columns()
            .search('')
            .draw();
        });
      };

      renderBtnClear('.dataTables_filter');
      resetFilter();
    }

    $.getJSON(ajaxPath, json => {
      const arr = json;

      // Sort array alphabetically before rendering table
      arr.data.sort(function sortResults(a, b) {
        const nameA = a.lastName.toUpperCase();
        const nameB = b.lastName.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      renderTable(arr.data);
    });
  };

  const init = () => {
    if ($('#faculty-table').length) {
      directoryInit();
    }
  };

  return {
    init,
  };
})();

export default facultyTable;
