const catalogProgram = (() => {
  const init = () => {
    if (!$('#program-list__table').length) {
      return;
    }

    // eslint-disable-next-line global-require
    Window.DataTable = require('datatables.net')();

    function appendButton() {
      $('.catalog-program-list__reset').appendTo('#program-list__table_filter');
    }

    $('.catalog-program-list__togglebtn').on('click', function() {
      if ($(this).attr('aria-expanded') === 'false') {
        $(this).attr('aria-expanded', 'true');
        $('.dataTables_filter').slideDown();
      } else {
        $(this).attr('aria-expanded', 'false');
        $('.dataTables_filter').slideUp();
      }
    });

    const table = $('#program-list__table')
      .on('init.dt', appendButton)
      .DataTable({
        paging: false,
        language: {
          search: 'Search Within Table:',
          searchPlaceholder: 'type to filter',
        },
      });

    function clearTable() {
      table.search('').draw();
    }

    $('.catalog-program-list__reset').click(clearTable);

    function scrollToTop() {
      $('html,body').animate({ scrollTop: 0 }, 300);
    }

    $('.catalog-program-list__top').click(scrollToTop);
  };

  return {
    init,
  };
})();

export default catalogProgram;
