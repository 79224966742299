/**
 * Accordion-simple displays main navigation
 * @param {*} $
 * @returns {init} Determine and run if main-nav present
 */

// eslint-disable-next-line global-require
require('../vendor/scrollsync');

const $ = require('jquery');

const tableScroll = (() => {
  // Add table scroll buttons to DOM
  const $tableScroll = $('table .table-scroller');
  const $tableWrap = $('.table-block__holder');
  const $table = $('.table-block table');

  function tableNext() {
    const elWidth = $(this)
      .siblings($table)
      .parent()
      .width();

    $(this)
      .siblings($table)
      .find($tableScroll)
      .animate(
        {
          scrollLeft: `+=${elWidth}`,
        },
        300,
        'swing',
      );
  }

  function tablePrev() {
    const elWidth = $(this)
      .siblings($table)
      .parent()
      .width();
    $(this)
      .siblings($table)
      .find($tableScroll)
      .animate(
        {
          scrollLeft: `-=${elWidth}`,
        },
        300,
        'swing',
      );
  }

  function tableInit() {
    const wrapWidth = $tableWrap.outerWidth();
    const tableWidth = $tableWrap.find('tr').outerWidth();

    if (wrapWidth < tableWidth) {
      $tableWrap.addClass('js-scroll--right');
    } else {
      $tableWrap.removeClass('js-scroll--left js-scroll--right');
    }
  }

  function tableUpdateScroll() {
    const scrollLeft = $(this).scrollLeft();
    const scrollWidth = $(this)[0].scrollWidth - $tableWrap[0].clientWidth;
    const $thisWrapper = $(this).closest('.table-block__holder');
    if (scrollLeft === 0 && scrollWidth > 0) {
      // If at the begin scroll pos
      $thisWrapper.addClass('js-scroll--right');
      $thisWrapper.removeClass('js-scroll--left');
    } else if (scrollLeft === scrollWidth && scrollWidth > 0) {
      // If at the end scroll pos
      $thisWrapper.removeClass('js-scroll--right').addClass('js-scroll--left');
    } else {
      $thisWrapper.addClass('js-scroll--right js-scroll--left');
    }
  }

  const init = () => {
    if ($tableWrap.length) {
      $tableWrap.append(
        '<button class="table-block__prev" aria-hidden="true">Scroll Table Right</button>\n' +
          '<button class="table-block__next" aria-hidden="true">Scroll Table Left</button>',
      );

      $('.table-block__next').on('click', tableNext);
      $('.table-block__prev').on('click', tablePrev);

      $(window).on('load resize', tableInit);
      $tableScroll.on('scroll', tableUpdateScroll);

      $('.table-block__wrapper').each((i, elem) => {
        $(elem).find('thead').attr('name', `table${i}`);
        $(elem).find('tbody').attr('name', `table${i}`);
      })
    }
  };

  return {
    init,
  };
})();

export default tableScroll;
