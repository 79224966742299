/**
 * Sticky Navigation display object interactions
 * @param {*} $
 * @returns {init} Determine and run if Sticky Navigation present
 */

const stickynav = (() => {
  function tabLoop() {
    const tabOne = $('.sticky-nav__bttn');
    const tabTwo = $('.sticky-nav__cta:last-of-type');
    
    tabOne.on('keydown', e => {
      if (e.keyCode === 9 && e.shiftKey && $(window).width() < 1024) {
        if ($(e.target).attr('aria-expanded') === 'true') {
          e.preventDefault();
          tabTwo.focus();
        }
      }
    });
    
    tabTwo.on('keydown', e => {
      if (e.keyCode === 9 && !e.shiftKey && $(window).width() < 1024) {
        e.preventDefault();
        tabOne.focus();
      }
    });
  }

  function toggle() {
    const bttn = $('.sticky-nav__bttn');
    
    bttn.on('click', (e) => {
      const dropdown = $(e.target).next();
    
      if (e.target.getAttribute('aria-expanded') === 'false') {
        e.target.setAttribute('aria-expanded', 'true');
        dropdown.attr('aria-hidden', 'false');
        dropdown.slideDown(300);
      } else {
        e.target.setAttribute('aria-expanded', 'false');
        dropdown.attr('aria-hidden', 'true');
        dropdown.slideUp(300);
      }
    });
  }


  const init = () => {
    if (!$('.sticky-nav').length) {
      return;
    }

    tabLoop();
    toggle();
  };

  return {
    init,

  };
})();

export default stickynav;