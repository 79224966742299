/**
 * Interior navigation - fix to top of viewport logic
 * @param {*} $
 * @returns {init}
 */

const navSecondary = (() => {
  function init() {
    const $nav = $('.nav-secondary');

    if ($nav.length) {
      const topofDiv = $('.nav-secondary').offset().top;
      const height = 0;

      $(window).on('scroll', () => {
        if ($(window).scrollTop() > topofDiv + height) {
          $('.nav-secondary').addClass('js-top');
        } else {
          $('.nav-secondary').removeClass('js-top');
        }
      });
    }

    const bttn = $('.section-nav__bttn');

    $(document).on('keydown', (e) => {
      if (e.key === 'Escape') {
        if (bttn.attr('aria-expanded') === 'true') {
          bttn.trigger('click');
        }
      }
    });
  }

  return {
    init,
  };
})();

export default navSecondary;
