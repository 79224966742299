/**
 * Header display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if Header present
 */

const header = (() => {
  function navEsc() {
    $(document).on('keydown', (e) => {
      if (e.key === 'Escape') {
        if ($('.header-nav__bttn').attr('aria-expanded') === 'true') {
          $('.header-nav__bttn').trigger('click');
        }

        if ($('.header-search__bttn').attr('aria-expanded') === 'true') {
          $('.header-search__bttn').trigger('click');
        }
      }
    });
  }

  function toggleDropdown(e) {
    if (e.target.getAttribute('aria-expanded') === 'false') {
      e.target.setAttribute('aria-expanded', 'true');
      e.target.nextElementSibling.setAttribute('aria-hidden', 'false');
    } else {
      e.target.setAttribute('aria-expanded', 'false');
      e.target.nextElementSibling.setAttribute('aria-hidden', 'true');
    }
  }

  function toggleNav() {
    const $bttnSearch = $('.header-search__bttn');
    const $bttnNav = $('.header-nav__bttn');

    $bttnNav.on('click', (e) => {
      e.stopPropagation();
      let delay = 0;

      if ($bttnSearch.attr('aria-expanded') == 'true') {
        $bttnSearch.click();
        delay = 300;
      }

      setTimeout(() => {
        toggleDropdown(e);

        if ($bttnNav.attr('aria-expanded') == 'true') {
          window.scrollTo(0, 0);
          $('body,html').addClass('js-fixed');
        } else {
          $('body,html').removeClass('js-fixed');
        }
      }, delay);
    });
  }

  function toggleSearch() {
    const $bttnSearch = $('.header-search__bttn');
    const $bttnNav = $('.header-nav__bttn');

    $bttnSearch.on('click', (e) => {
      e.stopPropagation();
      let delay = 0;

      if ($bttnNav.attr('aria-expanded') == 'true') {
        $bttnNav.click();
        delay = 300;
      }

      setTimeout(() => {
        toggleDropdown(e);

        if ($bttnSearch.attr('aria-expanded') == 'true') {
          window.scrollTo(0, 0);
          $('body,html').addClass('js-fixed');
        } else {
          $('body,html').removeClass('js-fixed');
        }
      }, delay);
    });
  }

  function toggleAncillary() {
    const bttn = $('.ancillary__bttn');

    bttn.on('click', (e) => {
      e.preventDefault();
      e.stopPropagation();

      // 1149 - breakpoint to desktop ancillary styles
      if ($(window).width() <= 1149) {
        let bttnClicked = $(e.target);

        if (!bttnClicked.attr('aria-expanded')) {
          bttnClicked = $(e.target).parent();
        }

        const dropdown = bttnClicked.next();
        dropdown.slideToggle(300);
        if (bttnClicked.attr('aria-expanded') === 'false') {
          bttnClicked.attr('aria-expanded', 'true');
          dropdown.attr('aria-hidden', 'false');
        } else {
          bttnClicked.attr('aria-expanded', 'false');
          dropdown.attr('aria-hidden', 'true');
        }
      }
    });
  }

  function toggleNavItem() {
    const bttn = $('.lvl-sub__bttn');

    bttn.on('click', (e) => {
      const dropdown = $(e.target).next();

      if (e.target.getAttribute('aria-expanded') === 'false') {
        e.target.setAttribute('aria-expanded', 'true');
        dropdown.attr('aria-hidden', 'false');
        dropdown.slideDown(300);
      } else {
        e.target.setAttribute('aria-expanded', 'false');
        dropdown.attr('aria-hidden', 'true');
        dropdown.slideUp(300);
      }
    });
  }

  function setTabloopsSearch() {
    const $bttnSearch = $('.header-search__bttn');
    const $bttnForm = $('.header-search__form > button');

    $bttnSearch.on('keydown', e => {
      if (e.keyCode === 9 && e.shiftKey) {
        if ($(e.target).attr('aria-expanded') === 'true') {
          e.preventDefault();
          $bttnForm.focus();
        }
      }
    });

    $bttnForm.on('keydown', e => {
      if (e.keyCode === 9 && !e.shiftKey) {
        e.preventDefault();
        $bttnSearch.focus();
      }
    });
  }

  function setTabloopsNav() {
    const tabOne = $('.header-nav__bttn');
    const tabTwo = $('.header-nav__dropdown .social li:last-of-type a');

    tabOne.on('keydown', e => {
      if (e.keyCode === 9 && e.shiftKey) {
        if ($(e.target).attr('aria-expanded') === 'true') {
          e.preventDefault();
          tabTwo.focus();
        }
      }
    });

    tabTwo.on('keydown', e => {
      if (e.keyCode === 9 && !e.shiftKey) {
        e.preventDefault();
        tabOne.focus();
      }
    });
  }

  const init = () => {
    if (!$('.header').length) {
      return;
    }

    toggleNav();
    toggleSearch();
    toggleAncillary();
    setTabloopsNav();
    setTabloopsSearch();
    toggleNavItem();
    navEsc();
  };

  return {
    init,
  };
})();

export default header;