const wordWrap = (() => {
  const init = () => {
    const cta = $('.news--listing .news__title a, .home-news-3up .news__header, .cta--link, .column__title h3 > a, .genimglist__title h3 > a, .program-list__program > a, .stat3up__cta > a, .events3up__content h3 > a, .catalog-program-list__table td > a, .catalog-course-list__table h2 > a, .catalog__faculty-info .faculty-info__name a, .faculty__list a.link-name, .calendar__event__text h2 a, .home-feature__quote blockquote');

    cta.html(function() {
      // separate the text by spaces
      const $this = $.trim($(this).text());
      const text = $this.split(' ');
      // drop the last word and store it in a variable
      const last = text.pop();
      // join the text back and if it has more than 1 word add the span tag
      // to the last word
      return `${text.join(
        ' ',
      )} <span class="last-word">${last}<span class="arrow"></span></span>`;
    });
  };

  return {
    init,
  };
})();

export default wordWrap;
