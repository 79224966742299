/**
 * Init masonry layout for quote grid
 * @param {*} $
 * @returns {init} Determine and run if grid exists
 */

const quoteGrid = (() => {
  function renderMasonry() {
    $('.grid--tab-desk').masonry({
      // options
      itemSelector: '.grid-item',
      gutter: 40,
      percentPosition: true,
      horizontalOrder: 2,
    });
  }

  function mobileToggle() {
    const bttn = $('.grid__show-bttn');
    
    bttn.on('click', (e) => {
      const dropdown = $(e.target).next();
    
      if (e.target.getAttribute('aria-expanded') === 'false') {
        e.target.setAttribute('aria-expanded', 'true');
        dropdown.attr('aria-hidden', 'false');
        dropdown.slideDown(300);
      } else {
        e.target.setAttribute('aria-expanded', 'false');
        dropdown.attr('aria-hidden', 'true');
        dropdown.slideUp(300);
      }
    });
  }

  const init = () => {
    if (!$('.quote-grid').length) {
      return;
    }

    renderMasonry();

    mobileToggle();

    $(window).on('load', function(){
      $('.grid--tab-desk').masonry('layout');
    })
  };

  return {
    init,
  };
})();

export default quoteGrid;