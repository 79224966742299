import '@babel/polyfill';
import '@accessible360/accessible-slick';
import 'object-fit-images';

import accordion from './object/accordion';
import inlineVideo from './object/video';
import hero from './object/hero';
import column from './object/column';
import wordWrap from './object/word-wrap';
import quoteGrid from './object/quote-grid';
import instagram from './object/instagram';
import twitterLoader from './object/twitter';
import facultyTable from './object/faculty-table';
import catalogProgram from './object/catalog-program';
import carousel from './object/carousel';
import scrollTop from './object/scroll-top';
import programFinder from './object/program-finder';
import calendar from './object/calendar';
import catalog from './object/catalog';
import tableScroll from './object/scroll-table';
import header from './object/header';
import stickynav from './object/sticky-nav';
import sectionnav from './object/section-nav';
import navSecondary from './object/nav-secondary';

window.$ = require('jquery');
window.jQuery = require('jquery');
const objectFitImages = require('object-fit-images');

$(() => {
  accordion.init();
  inlineVideo.init();
  hero.init();
  instagram.init();
  twitterLoader.init();
  quoteGrid.init();
  facultyTable.init();
  calendar.init();
  catalogProgram.init();
  carousel.init();
  scrollTop.init();
  programFinder.init();
  catalog.init();
  tableScroll.init();
  header.init();
  stickynav.init();
  sectionnav.init();
  navSecondary.init();
  objectFitImages();

  // Run after instagram and others have loaded
  $(window).on('load resize', () => {
    setTimeout(() => {
      column.init();
      wordWrap.init();
    }, 100);
  });
});
