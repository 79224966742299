/**
 * fade in hero image on load
 * @param {*} $
 * @returns {init} Determine and run if img exists
 */

const hero = (() => {
  function init() {
    const $hero = $('.hero');
    const $heroHome = $('.hero--home');

    if ($hero.length) {
      $hero.addClass('js-loaded');
    }

    if ($heroHome.length) {
      const $video = $('#homeVideo')[0];
      const $vidWrapper = $('.hero__inner');
      const $btn = $('#videoBtn');
      $vidWrapper.click(() => {
        if (!$video.paused) {
          $video.pause();
          $btn.addClass('paused');
        } else {
          $video.play();
          $btn.removeClass('paused');
        }
      });
    }

  }

  return {
    init,
  };
})();

export default hero;