/**
 * Section Navigation display object interactions
 * @param {*} $
 * @returns {init} Determine and run if Section Navigation present
 */

const sectionnav = (() => {
  function setNavDisplay() {
    // closest polyfill - IE11
    if (!Element.prototype.matches) {
      Element.prototype.matches =
        Element.prototype.msMatchesSelector ||
        Element.prototype.webkitMatchesSelector;
    }
    
    if (!Element.prototype.closest) {
      Element.prototype.closest = function(s) {
        var el = this;
    
        do {
          if (Element.prototype.matches.call(el, s)) return el;
          el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
      };
    }

    $('.section-nav__list .active-parent').removeClass('active-parent');
    
    // hide all top level navigation anchors and buttons from view
    $('.section-nav__list li').each((i, elem) => {
      if (elem.closest('.active') == null) {
        $(elem).children('a').css({
          'display': 'none'
        });
        $(elem).children('button').css({
          'display': 'none'
        });
      }
    });

    // Show parent element
    //$('.section-nav__list .active').parent().parent().children('a').removeAttr('style');

    // hide back button if at top level of navigation
    if ($('.section-nav__list > li.active').length) {
      $('.section-nav__back').css({
        'display': 'none'
      });
    } else {
      $('.section-nav__back').removeAttr('style');
    }

    $('.section-nav__list .active').children('a').focus();


    if (!$('.section-nav__list .active').children('ul').length) {
      $('.section-nav__list .active').removeAttr('style');
      $('.section-nav__list .active').parent().addClass('active-parent');
      $('.section-nav__list .active').parent().parent().children('a').removeAttr('style');
    }

    // If active page doesn't have children, show siblings
    if (!$('.section-nav__list .active').children('.list__bttn').length) {
      $('.section-nav__list .active').siblings('li').find('> a, > .list__bttn').removeAttr('style');
    }
  }

  function clickActiveLink() {
    $('.section-nav__list .list__bttn').on('click', (e) => {
      const elem = $(e.target);

      $('.section-nav__list li').removeClass('active');
      $('.section-nav__list li').removeAttr('style');
      $('.section-nav__list a').removeAttr('style');
      $('.section-nav__list button').removeAttr('style');

      elem.parent().addClass('active');

      setNavDisplay();
    });
  }

  function clickBackButton() {
    $('.section-nav__back').on('click', (e) => {
      $('.section-nav__list li').removeAttr('style');
      $('.section-nav__list a').removeAttr('style');
      $('.section-nav__list button').removeAttr('style');

      // If bottom level link: no children
      if (!$('.section-nav__list .active').children('a').length) {
        const $activeLink = $('.section-nav__list .active');
        $('.section-nav__list li').removeClass('active');
        $activeLink.parent().parent().parent().addClass('active');
      } else {
        // link with children
        const $activeLink = $('.section-nav__list .active');
        $('.section-nav__list li').removeClass('active');
        $activeLink.parent().parent().addClass('active');
      }

      $('.section-nav__list').removeClass('active');

      setNavDisplay();
    });
  }

  function sectionToggle() {
    const bttn = $('.section-nav__bttn');

    bttn.on('click', (e) => {
      const dropdown = $(e.target).next();

      if (e.target.getAttribute('aria-expanded') === 'false') {
        e.target.setAttribute('aria-expanded', 'true');
        dropdown.attr('aria-hidden', 'false');
        dropdown.slideDown(300);
      } else {
        e.target.setAttribute('aria-expanded', 'false');
        dropdown.attr('aria-hidden', 'true');
        dropdown.slideUp(300);
      }
    });
  }


  const init = () => {
    if (!$('.section-nav').length) {
      return;
    }

    $('.current-page').parents('li').addClass('path');

    clickActiveLink();
    clickBackButton();
    setNavDisplay();
    sectionToggle();
  };

  return {
    init,

  };
})();

export default sectionnav;